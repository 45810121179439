import React, { useState, useEffect }  from "react"
import Layout from "../components/layout"


const TestBooking = ({data}) => {
  return (
    <Layout headerColor="bg-grey opacity-1">
      <section className="book book-sec-test py-[30vw] md:py-[15vw] lg:py-[12vw]">
        <div className="container-v text-white">
          <div data-calendar_url="https://book.sognarsail.com/book" class="rvrd_calendar">Calendar Here</div>
        </div>
      </section>
    </Layout>
  )
}

export default TestBooking
